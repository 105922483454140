<template>
  <span class="loading-gif">
    <img v-if="size === 'lg'" src="/img/loading.gif" />
    <img class="sm-gif" v-if="size === 'sm'" src="/img/loading-sm.gif" />
    <img v-else src="/img/loading.gif" />
  </span>
</template>

<script>
export default {
  name: "LoadingGif",
  props: ["size"],
};
</script>

<style scoped>
.loading-gif {
  margin-left: 25%;
  margin-top: 15%;
}
.sm-gif {
  height: 15px;
  width: 15px;
}
</style>
